import React from "react";
import { Col, Row } from "antd";
import { v4 as uuidv4 } from "uuid";
import ProductPrev from "./ProductPrev";

const data = [
  {
    id: uuidv4(),
    title: "Lorem, ipsum",
    img1: "p-44_5.jpg",
    img2: "p-44_5_1.jpg",
    url: "#",
    price: 1500,
    rating: 5,
    rates: 2,
  },
  {
    id: uuidv4(),
    title: "Unde, aut",
    img1: "m-11_1_3.jpg",
    img2: "m-12_1_2.jpg",
    url: "#",
    price: 1300,
    oldPrice: 1700,
    sale: true,
    rating: 4.5,
    rates: 3,
  },
  {
    id: uuidv4(),
    title: "Consequatur, ipsum",
    img1: "g-01_1_1.jpg",
    img2: "g-1_1_2.jpg",
    url: "#",
    price: 2300,
    oldPrice: 2900,
    sale: true,
    rating: 5,
    rates: 1,
  },
];

export default function RelatedProducts() {
  return (
    <div id="related-products">
      <div className="title">Lorem ipsum dolor sit.</div>
      <Row gutter={20}>
        {data.map((x) => (
          <ProductPrev {...x} />
        ))}
      </Row>
    </div>
  )
}