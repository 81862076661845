import React from "react";
import { Col, Row } from "antd";
import { v4 as uuidv4 } from "uuid";
import { ArrowRightOutlined, CalendarOutlined } from "@ant-design/icons";
import PostInfo from "./PostInfo";
import { Link } from "react-router-dom";
import Paginator from "./Paginator";

const data = [
  {
    id: uuidv4(),
    title: "Lorem, ipsum",
    img: "post11.jpg",
    url: "#",
    price: 1500,
    rating: 5,
    rates: 2,
    date: "24.04.24",
    categories: ["Lorem", "Dicta"],
  },
  {
    id: uuidv4(),
    title: "Unde, aut",
    img: "post12.jpg",
    url: "#",
    price: 1300,
    oldPrice: 1700,
    sale: true,
    rating: 4.5,
    rates: 3,
    date: "24.04.24",
    categories: ["Eligendi", "Mollitia", "Eveniet", "Veritatis", "Dicta"],
  },
  {
    id: uuidv4(),
    title: "Consequatur, ipsum",
    img: "post08.jpg",
    url: "#",
    price: 2300,
    oldPrice: 2900,
    sale: true,
    rating: 5,
    rates: 1,
    date: "24.04.24",
    categories: ["Eveniet", "Veritatis", "Dicta"],
  },
  {
    id: uuidv4(),
    title: "Animi, rerum",
    img: "post13.jpg",
    url: "#",
    price: 2500,
    oldPrice: 3200,
    sale: true,
    rating: 5,
    rates: 1,
    date: "24.04.24",
    categories: ["Veritatis"],
  },
  {
    id: uuidv4(),
    title: "Ut, ipsam",
    img: "post14.jpg",
    url: "#",
    price: 1800,
    oldPrice: 2200,
    sale: true,
    rating: 4.5,
    rates: 2,
    date: "24.04.24",
    categories: ["Mollitia", "Veritatis", "Dicta"],
  },
  {
    id: uuidv4(),
    title: "Nihil, tempora",
    img: "g-4_1.jpg",
    url: "#",
    price: 3200,
    rating: 5,
    rates: 2,
    date: "24.04.24",
    categories: ["Lorem", "Animi"],
  },
];

export default function NewsSection() {
  return (
    <div id="news">
      {data.map((x) => (
        <div className="prev">
          <Row gutter={30}>
            <Col span={24} sm={{ span: 11 }}>
              <div className="border">
                <div
                  className="img"
                  style={{
                    background: `url(${require("../img/" +
                      x.img)}) no-repeat center center / cover`,
                  }}
                ></div>
              </div>
            </Col>
            <Col span={24} sm={{ span: 13 }}>
              <div className="post-prev-content">
                <Link to={`/news/${x.id}`}>
                  <div className="title">{x.title}</div>
                </Link>
                <PostInfo date={x.date} categories={x.categories} />
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga
                  amet dicta accusamus earum voluptatibus facilis nemo maiores
                  dolorem corrupti reprehenderit.
                </p>
                <div className="more">
                  <span>Read more</span>
                  <ArrowRightOutlined />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ))}
      <Paginator />
    </div>
  );
}
