import React from "react";
import { Col, Row } from "antd";
import Container from "./Container";
import CollectionPrev from "./CollectionPrev";

const data = [
  { title: "Lorem", img: "1a.jpg", url: "#" },
  { title: "Labore", img: "1b.jpg", url: "#" },
  { title: "Fuga", img: "1c.jpg", url: "#" },
  { title: "Possimus", img: "1d.jpg", url: "#" },
];

export default function BigCollection() {
  return (
    <div id="big-collection">
      <Container>
        <Row gutter={30} className='collection-wrapper'>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <CollectionPrev {...data[0]} />
            <CollectionPrev {...data[1]} />
          </Col>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <CollectionPrev {...data[2]} />
            <CollectionPrev {...data[3]} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
