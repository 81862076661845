import React from "react";
import { Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import Social from "./Social";

const Lang = ({ img, text }) => (
  <div className="lang">
    <img src={require(`../img/${img}`)} alt="" />
    {text}
  </div>
);

const langItems = (
  <Menu
    items={[
      {
        label: <Lang img="en.png" text="English" />,
        key: uuidv4(),
      },
      {
        label: <Lang img="de.png" text="German" />,
        key: uuidv4(),
      },
    ]}
  />
);

const currency = (
  <Menu
    items={[
      {
        label: <div onClick={(x) => console.log(x)}>USD</div>,
        key: uuidv4(),
      },
      {
        label: <div onClick={(x) => console.log(x)}>EUR</div>,
        key: uuidv4(),
      },
    ]}
  />
);

export default function Upper() {
  return (
    <section id="upper">
      <div className="box">
        <Social />
        <div id="upper-title">Lorem, ipsum dolor</div>
        <div id="upper-controls">
          <Dropdown overlay={langItems}>
            <div onClick={(e) => e.preventDefault()}>
              <Space>
                <Lang img="en.png" text="English" />
                <DownOutlined />
              </Space>
            </div>
          </Dropdown>
          <div id="currency">
            <Dropdown overlay={currency}>
              <div onClick={(e) => e.preventDefault()}>
                <Space>
                  USD
                  <DownOutlined />
                </Space>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      {/* <MenuOutlined /> */}
    </section>
  );
}
