import React from "react";
import { Col, Row } from "antd";
import { v4 as uuidv4 } from "uuid";

const data = [
  {
    title: "Lorem ipsum dolor sit amet consectetur",
    excerpt:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque, atque a! Laborum, officiis",
    img: "post11.jpg",
    url: "#",
    date: "24.04.24",
    author: "Viggo Drantiev",
  },
  {
    title: "Aliquid laborum esse repellat molestiae est",
    excerpt:
      "Quas, magnam. Quia eius, voluptatibus animi laborum corrupti in omnis unde quos officiis",
    img: "post12.jpg",
    url: "#",
    date: "24.04.24",
    author: "Viggo Drantiev",
  },
  {
    title: "Vitae eos sed dolorum! Aperiam, qui!",
    excerpt:
      "Excepturi, unde aspernatur. Officiis quod tempore tempora recusandae alias hic beatae exercitationem corporis",
    img: "post08.jpg",
    url: "#",
    date: "24.04.24",
    author: "Viggo Drantiev",
  },
  {
    title: "Officiis rem facere facilis magnam ullam?",
    excerpt:
      "Sed officiis assumenda placeat officia repudiandae dolores laboriosam sequi. Architecto sunt deserunt nostrum",
    img: "post13.jpg",
    url: "#",
    date: "24.04.24",
    author: "Viggo Drantiev",
  },
  {
    title: "Autem ea praesentium totam amet similique!",
    excerpt:
      "Magni, voluptatum eveniet. Error laborum asperiores magnam autem distinctio delectus, maiores doloremque sint",
    img: "post14.jpg",
    url: "#",
    date: "24.04.24",
    author: "Viggo Drantiev",
  },
];

export default function RelatedPosts() {
  return (
    <div id="related-posts">
      <div className="title">Lorem, ipsum dolor.</div>
      <Row gutter={20}>
        {data.map((x) => (
          <PostPrev {...x} />
        ))}
      </Row>
    </div>
  );
}

const PostPrev = ({ date, img, title }) => {
  return (
    <Col xs={{ span: 24 }} sm={{ span: 8 }}>
      <div className="prev">
        <div className="border">
          <div
            className="img"
            style={{ background: `url(${require("../img/" + img)}) no-repeat center center / cover` }}
          ></div>
        </div>
        <div className="prev-content">
          <div className="prev-title">{title}</div>
          <div className="date">{date}</div>
        </div>
      </div>
    </Col>
  );
};
