import React from "react";
import { Button, Typography } from "antd";
import Slider from "react-slick";
import Container from "./Container";

const { Text } = Typography;

const data = [
  {
    title: "Lorem ipsum dolor sit amet consectetur",
    excerpt:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque, atque a! Laborum, officiis",
    img: "post11.jpg",
    url: "#",
    date: "24.04.24",
    author: "Viggo Drantiev",
  },
  {
    title: "Aliquid laborum esse repellat molestiae est",
    excerpt:
      "Quas, magnam. Quia eius, voluptatibus animi laborum corrupti in omnis unde quos officiis",
    img: "post12.jpg",
    url: "#",
    date: "24.04.24",
    author: "Viggo Drantiev",
  },
  {
    title: "Vitae eos sed dolorum! Aperiam, qui!",
    excerpt:
      "Excepturi, unde aspernatur. Officiis quod tempore tempora recusandae alias hic beatae exercitationem corporis",
    img: "post08.jpg",
    url: "#",
    date: "24.04.24",
    author: "Viggo Drantiev",
  },
  {
    title: "Officiis rem facere facilis magnam ullam?",
    excerpt:
      "Sed officiis assumenda placeat officia repudiandae dolores laboriosam sequi. Architecto sunt deserunt nostrum",
    img: "post13.jpg",
    url: "#",
    date: "24.04.24",
    author: "Viggo Drantiev",
  },
  {
    title: "Autem ea praesentium totam amet similique!",
    excerpt:
      "Magni, voluptatum eveniet. Error laborum asperiores magnam autem distinctio delectus, maiores doloremque sint",
    img: "post14.jpg",
    url: "#",
    date: "24.04.24",
    author: "Viggo Drantiev",
  },
];

const settings = {
  // infinite: true,
  // autoplay: true,
  // speed: 2000,
  // autoplaySpeed: 5000,
  slidesToShow: 3,
  slidesToScroll: 1,
};

export default function MainBlog() {
  return (
    <div id="main-blog">
      <Container>
        <div className="title section-title">Lorem, ipsum dolor.</div>
        <div id="main-blog-slider">
          <Slider {...settings}>
            {data.map((x) => (
              <Prev1 {...x} />
            ))}
          </Slider>
        </div>
        <div className="section-expand center">
          <Button type="primary" className="xbutton2">
            Lorem ipsum
          </Button>
        </div>
      </Container>
    </div>
  );
}

function Prev1({ title, excerpt, img, url, date, author }) {
  return (
    <div className="prev1">
      <div className="border">
        <img src={require(`../img/${img}`)} alt="" />
      </div>
      <div className="prev-content">
        <div className="title">
          <a href={url}>{title}</a>
        </div>
        <div className="post-details">
          <Text type="secondary">
            <span className="post-date">{date}</span>
            Posted by
            <strong> {author}</strong>
          </Text>
        </div>
        <Text type="secondary">{excerpt}</Text>
      </div>
    </div>
  );
}
