import {
  CaretLeftOutlined,
  CaretRightOutlined,
  ClockCircleOutlined,
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row } from "antd";
import React, { Fragment } from "react";
import Comments from "../components/Comments";
import Container from "../components/Container";
import MapContainer from "../components/MapContainer";
import NewsPanel from "../components/NewsPanel";
import PostInfo from "../components/PostInfo";
import RelatedPosts from "../components/RelatedPosts";
import RelatedProducts from "../components/RelatedProducts";
import SubHeader2 from "../components/SubHeader2";

const { TextArea } = Input;

export default function Contacts() {
  const [form] = Form.useForm();
  return (
    <Fragment>
      <SubHeader2 />
      <MapContainer />
      <Container>
        <div id="contacts" className="page-content-wrapper">
          <Row gutter={30}>
            <Col sm={{ span: 24 }} md={{ span: 12 }}>
              <div className="title">Lorem, ipsum dolor.</div>
              <div className="contacts-content">
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={(values) => {
                    // reset && form.resetFields();
                  }}
                >
                  <Form.Item name="name" label="Name">
                    <Input placeholder="Enter name" />
                  </Form.Item>
                  <Form.Item name="email" label="Email">
                    <Input placeholder="Enter email" />
                  </Form.Item>
                  <Form.Item name="phone" label="Phone">
                    <Input placeholder="Enter phone" />
                  </Form.Item>
                  <Form.Item name="message" label="Message">
                    <TextArea
                      autoSize
                      style={{ minHeight: 100 }}
                      placeholder="Enter message"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="xbutton2"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 12 }}>
              <div className="title">Minima, a temporibus</div>
              <div className="contacts-content">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Dignissimos amet nulla explicabo odio, corporis temporibus
                  perferendis repellendus iure quae, minus repellat beatae
                  aperiam incidunt quo debitis obcaecati eius eligendi
                  similique.
                </p>
                <ul className="contacts-list">
                  <li>
                    <HomeOutlined /> 184 Main Rd E, St Albans Victoria 3021,
                    Australia
                  </li>
                  <li>
                    <PhoneOutlined />
                    1800-123-222 / 1900-1570-230
                  </li>
                  <li>
                    <MailOutlined />
                    qwerty@gmail.com
                  </li>
                  <li>
                    <ClockCircleOutlined />
                    Everyday 9:00-17:00
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}
