import React from 'react'
import { Button } from 'antd'

export default function Promotion({ text1, text2, text3, img, url }) {
  return (
    <div id="pro-banner">
      <div className="dark"></div>
      <div className="caption">
        <div className="title title5">{text1}</div>
        <div className="title title1">
          <span className="highlighted">{text2}</span> {text3}
        </div>
        <Button type="primary" className="xbutton">
          Lorem ipsum
        </Button>
      </div>
    </div>
  )
}
