import React from "react";
import { Col, Row } from "antd";
import { v4 as uuidv4 } from "uuid";
import SearchForm from "./SearchForm";

const data = [
  {
    id: uuidv4(),
    title: "Lorem, ipsum",
    img: "post08.jpg",
    img2: "p-44_5_1.jpg",
    url: "#",
    price: 1500,
    rating: 5,
    rates: 2,
  },
  {
    id: uuidv4(),
    title: "Unde, aut",
    img: "post11.jpg",
    img2: "m-12_1_2.jpg",
    url: "#",
    price: 1300,
    oldPrice: 1700,
    sale: true,
    rating: 4.5,
    rates: 3,
  },
  {
    id: uuidv4(),
    title: "Consequatur, ipsum",
    img: "post12.jpg",
    img2: "g-1_1_2.jpg",
    url: "#",
    price: 2300,
    oldPrice: 2900,
    sale: true,
    rating: 5,
    rates: 1,
  },
  {
    id: uuidv4(),
    title: "Animi, rerum",
    img: "post13.jpg",
    img2: "m-15_1.jpg",
    url: "#",
    price: 2500,
    oldPrice: 3200,
    sale: true,
    rating: 5,
    rates: 1,
  },
  {
    id: uuidv4(),
    title: "Ut, ipsam",
    img: "post14.jpg",
    img2: "grey-2_1_6.jpg",
    url: "#",
    price: 1800,
    oldPrice: 2200,
    sale: true,
    rating: 4.5,
    rates: 2,
  },
];

const tags = [
  "Lorem",
  "Animi",
  "Eligendi",
  "Mollitia",
  "Eveniet",
  "Veritatis",
  "Dicta",
];

export default function NewsPanel() {
  return (
    <div id="news-panel" className="panel">
      <div className="panel-section">
        <div className="title">Search</div>
        <SearchForm text="Search" />
      </div>
      <div className="panel-section">
        <div className="title">Categories</div>
        <ul className="standart-list">
          <li>Lorem, ipsum (5)</li>
          <li>Labore, id! (3)</li>
          <li>Voluptatum, quo. (11)</li>
        </ul>
      </div>
      <div id="panel-posts" className="panel-section">
        <div className="title">Recent news</div>
        {data.map((x) => (
          <div className="prev">
            <Row gutter={30}>
              <Col span={10}>
                <div className="border">
                  <div
                    className="img"
                    style={{ background: `url(${require("../img/" + x.img)}) no-repeat center center / cover` }}
                  ></div>
                </div>
              </Col>
              <Col span={14}>
                <div className="title">{x.title}</div>
              </Col>
            </Row>
          </div>
        ))}
      </div>
      <div className="panel-section">
        <div className="title">Archive</div>
        <ul className="standart-list">
          <li>Lorem, ipsum</li>
        </ul>
      </div>
      <div className="panel-section">
        <div className="title">Tags</div>
        <div className="tags">
          {tags.map((x) => (
            <div className="tag-btn">{x}</div>
          ))}
        </div>
      </div>
    </div>
  );
}
