import { Badge } from "antd";
import React from "react";

export default function NavControls() {
  return (
    <ul id="nav-controls">
      <li>
        <i class="pe-7s-search"></i>
      </li>
      <li>
        <i class="pe-7s-user"></i>
      </li>
      <li>
        <i class="pe-7s-like"></i>
      </li>
      <li>
        <Badge count={3}>
          <i class="pe-7s-shopbag"></i>
        </Badge>
      </li>
    </ul>
  );
}
