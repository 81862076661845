import React from "react";
import { v4 as uuidv4 } from "uuid";
import Container from "./Container";
import Products from "./Products";

const data = [
  {
    id: uuidv4(),
    title: "Dicta, aspernatur",
    img1: "p-21_1_5.jpg",
    img2: "p-23_9.jpg",
    url: "#",
    price: 2300,
    oldPrice: 2900,
    sale: true,
    rating: 5,
    rates: 1,
  },
  {
    id: uuidv4(),
    title: "Soluta, mollitia",
    img1: "g-2_3.jpg",
    img2: "g-03_3.jpg",
    url: "#",
    price: 2500,
    oldPrice: 3200,
    sale: true,
    rating: 5,
    rates: 1,
  },
  {
    id: uuidv4(),
    title: "Numquam, itaque",
    img1: "p-44_5.jpg",
    img2: "p-46_2.jpg",
    url: "#",
    price: 1800,
    oldPrice: 2200,
    sale: true,
    rating: 4.5,
    rates: 2,
  },
];

export default function OurProducts() {
  return (
    <div id="products" className="standart-section">
      <Container>
        <div className="title section-title center">Lorem, ipsum.</div>
        <Products data={data} />
      </Container>
    </div>
  )
}
