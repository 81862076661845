import { Col, Row } from "antd";
import React, { Fragment } from "react";
import { v4 as uuidv4 } from "uuid";
import Container from "../components/Container";
import Products from "../components/Products";
import ProductsControls from "../components/ProductsControls";
import ProductsPanel from "../components/ProductsPanel";
import SubHeader2 from "../components/SubHeader2";

const data = [
  {
    id: uuidv4(),
    title: "Lorem, ipsum",
    img1: "p-44_5.jpg",
    img2: "p-46_2.jpg",
    url: "#",
    price: 1500,
    rating: 5,
    rates: 2,
  },
  {
    id: uuidv4(),
    title: "Unde, aut",
    img1: "m-11_1_3.jpg",
    img2: "m-12_1_2.jpg",
    url: "#",
    price: 1300,
    oldPrice: 1700,
    sale: true,
    rating: 4.5,
    rates: 3,
  },
  {
    id: uuidv4(),
    title: "Consequatur, ipsum",
    img1: "g-01_1_1.jpg",
    img2: "g-1_1_2.jpg",
    url: "#",
    price: 2300,
    oldPrice: 2900,
    sale: true,
    rating: 5,
    rates: 1,
  },
  {
    id: uuidv4(),
    title: "Nihil, tempora",
    img1: "g-4_1.jpg",
    img2: "grey-1_1_6.jpg",
    url: "#",
    price: 3200,
    rating: 5,
    rates: 2,
  },
];

export default function ProductsRoute() {
  return (
    <Fragment>
      <SubHeader2 />
      <Container>
        <div className="page-content-wrapper">
          <Row gutter={20}>
            <Col span={0} md={{ span: 8 }}>
              <ProductsPanel />
            </Col>
            <Col span={24} md={{ span: 16 }}>
              <ProductsControls />
              <Products data={data} />
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}
