import React from 'react'

export default function Crumbs() {
  return (
    <ul className="crumbs">
      <li>Lorem.</li>
      <li>Minus!</li>
      <li>Eum?</li>
      <li>Officia?</li>
    </ul>
  )
}
