import React, { Fragment } from 'react'
import BigCollection from '../components/BigCollection'
import BottomBanner from '../components/BottomBanner'
import Latest from '../components/Latest'
import MainBlog from '../components/MainBlog'
import OurProducts from '../components/OurProducts'
import Promotion from '../components/Promotion'
import SubHeader from '../components/SubHeader'

const promotionData = {
  text1: 'Facere, ad magnam',
  text2: 'Exercitationem',
  text3: 'ipsa quisquam',
  url: '#'
}

export default function Main() {
  return (
    <Fragment>
      <SubHeader />
      <BigCollection />
      <Latest />
      <Promotion {...promotionData} />
      <OurProducts />
      <MainBlog />
      {/* <InstagramBanner /> */}
      <BottomBanner />
    </Fragment>
  )
}
