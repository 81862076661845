import { Col, Row } from "antd";
import React, { Fragment } from "react";
import { v4 as uuidv4 } from "uuid";
import Container from "../components/Container";
import NewsPanel from "../components/NewsPanel";
import NewsSection from "../components/NewsSection";
import SubHeader2 from "../components/SubHeader2";

export default function News() {
  return (
    <Fragment>
      <SubHeader2 />
      <Container>
        <div className="page-content-wrapper">
          <Row gutter={30}>
            <Col span={24} order={2} md={{ span: 8, order: 1 }}>
              <NewsPanel />
            </Col>
            <Col span={24} order={1} md={{ span: 16, order: 2 }}>
              <NewsSection />
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}
