import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, Layout, Menu } from "antd";
import { v4 as uuidv4 } from "uuid";
import NavControls from "./NavControls";

const { Header } = Layout;

const items = [
  {
    label: <Link to="products">Products</Link>,
    key: uuidv4(),
  },
  {
    label: <Link to="contacts">Contacts</Link>,
    key: uuidv4(),
  },
  {
    label: <Link to="lookbook">Lookbook</Link>,
    key: uuidv4(),
  },
  {
    label: <Link to="news">News</Link>,
    key: uuidv4(),
  },
  {
    label: "Velit",
    key: uuidv4(),
    children: [
      {
        label: "Culpa",
        key: uuidv4(),
      },
      {
        label: "Voluptate",
        key: uuidv4(),
      },
    ],
  },
];

export default function TopNav() {
  const [visible, setVisible] = useState(false);
  return (
    <Fragment>
      <Header>
        <div className="box">
          <div id="hamburger" onClick={() => setVisible(!visible)}>
            <img src="img/hamburger-black.svg" alt="" />
          </div>
          <div className="logo">
            <Link to="/">
              <span>Qwerty</span>
            </Link>
          </div>
          <div id="topnav">
            <Menu
              mode="horizontal"
              items={items}
              triggerSubMenuAction="click"
            />
          </div>
          <div id="header-controls">
            <NavControls />
          </div>
        </div>
      </Header>
      <Drawer
        title="Menu"
        placement="left"
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <Menu mode="inline" items={items} />
      </Drawer>
    </Fragment>
  );
}
