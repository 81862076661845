import React from "react";
import { Space, Typography } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

const { Text, Link } = Typography;

export default function CollectionPrev({ title, img, url }) {
  return (
    <div className="col-prev">
      <a href={url}>
        <img src={require(`../img/${img}`)} alt="" />
      </a>
      <div className="col-caption">
        <div className="title">{title}</div>
        {/* <i>Shop now</i> */}
        <a href="#">
          <Text italic type="secondary">
            Shop now
            <ArrowRightOutlined />
          </Text>
        </a>
      </div>
    </div>
  );
}
