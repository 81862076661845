import { ArrowUpOutlined } from "@ant-design/icons";
import { Col, Modal, Row, Select } from "antd";
import React, { Fragment, useState } from "react";
import ProductsPanel from "./ProductsPanel";

const { Option } = Select;

const options = [
  "Lorem, ipsum",
  "Veniam, ipsum",
  "Voluptate, sequi",
  "Ipsum, facere!",
  "Ut, placeat",
];

export default function ProductsControls() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <Fragment>
      <div id="products-controls" className="controls">
        <Row gutter={20}>
          <Col span={8}>
            <div
              className="control"
              id="panel-trigger"
              onClick={() => setIsModalVisible(!isModalVisible)}
            >
              Shop by
            </div>
            <div className="control control1">
              <div className="grid-control">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="control control1">
              <div className="list-control">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </Col>
          <Col span={16}>
            <div className="control">
              <Select defaultValue={options[0]}>
                {options.map((x) => (
                  <Option value={x}>{x}</Option>
                ))}
              </Select>
            </div>
            <div className="control control1">
              <Select defaultValue={options[4]}>
                {options.reverse().map((x) => (
                  <Option value={x}>{x}</Option>
                ))}
              </Select>
            </div>
            <div className="control control1">
              <ArrowUpOutlined />
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        destroyOnClose
        title="Shopping options"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        wrapClassName="panel-modal"
        footer={false}
      >
        <ProductsPanel />
      </Modal>
    </Fragment>
  );
}
