import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Rate, Row, Tag, Typography } from "antd";
import { EyeOutlined, HeartOutlined, SwapOutlined } from "@ant-design/icons";
import ProductPrev from "./ProductPrev";

const { Text } = Typography;

export default function Products({ data }) {
  return (
    <div id="products">
      <Row gutter={20}>
        {data.map((x) => (
          <ProductPrev {...x} />
        ))}
      </Row>
    </div>
  );
}