import React, { useState } from "react";
import Container from "./Container";

export default function Lower() {
  const [date, setDate] = useState(new Date().getFullYear());
  return (
    <section id="lower">
      <Container>
        <div className="lower-container">
          <div className="lower1">
            {`Copyright © ${date} Qwerty all rights reserved. Powered by Zgroup`}
          </div>
          <div className="lower2">
            <ul>
              <li>
                <a href="#">Lorem.</a>
              </li>
              <li>
                <a href="#">Dolore.</a>
              </li>
              <li>
                <a href="#">Quos?</a>
              </li>
              <li>
                <a href="#">Iure.</a>
              </li>
              <li>
                <a href="#">Numquam!</a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </section>
  );
}
