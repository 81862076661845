import React from "react";
import { Button, Carousel } from "antd";

const data = [
  {
    text1: "Lorem, ipsum",
    text2: "Numquam,",
    text3: "facilis illo",
    img: "slide-3.jpg",
  },
  {
    text1: "Aspernatur, voluptatem?",
    text2: "Molestias,",
    text3: "inventore repellendus",
    img: "slide-2.jpg",
  },
  {
    text1: "Perspiciatis, mollitia",
    text2: "Nesciunt,",
    text3: "provident dicta",
    img: "slide-1.jpg",
  },
];

export default function SubHeader() {
  return (
    <div id="sub-header1">
      <Carousel>
        {data.map((x) => (
          <Slide {...x} />
        ))}
      </Carousel>
    </div>
  );
}

const Slide = ({ text1, text2, text3, img, url }) => {
  return (
    <div className="slide">
      <img src={require(`../img/${img}`)} alt="" />
      <div className="dark"></div>
      <div className="caption">
        <div className="title title5">{text1}</div>
        <div className="title title1">
          <span className="highlighted">{text2}</span> {text3}
        </div>
        <Button type="primary" className="xbutton">
          Lorem ipsum
        </Button>
      </div>
    </div>
  );
};
