import {
  FacebookOutlined,
  InstagramOutlined,
  LikeOutlined,
  LinkedinOutlined,
  SwapOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Col, Form, InputNumber, Rate, Row, Tag } from "antd";
import React, { Fragment } from "react";
import { v4 as uuidv4 } from "uuid";
import Container from "../components/Container";
import SubHeader2 from "../components/SubHeader2";

export default function Product() {
  return (
    <Fragment>
      <SubHeader2 />
      <Container>
        <div className="page-content-wrapper">
          <Row gutter={30}>
            <Col span={24} md={{ span: 12 }}>
              <div className="product-media-wrapper">
                <div className="product-media">
                  <div className="images-list">
                    <div className="images-list-item">
                      <img src={require("../img/black11_4.jpg")} alt="" />
                    </div>
                    <div className="images-list-item">
                      <img src={require("../img/black12_6.jpg")} alt="" />
                    </div>
                    <div className="images-list-item">
                      <img src={require("../img/black13_6.jpg")} alt="" />
                    </div>
                    <div className="images-list-item">
                      <img src={require("../img/blue13_3.jpg")} alt="" />
                    </div>
                    <div className="images-list-item">
                      <img src={require("../img/blue11_6.jpg")} alt="" />
                    </div>
                  </div>
                  <div className="main-image">
                    <img src={require("../img/blue13_3.jpg")} alt="" />
                    <div className="caption">
                      <Tag color="magenta">magenta</Tag>
                      <Tag color="red">red</Tag>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <div id="product-content">
                <div className="title">Lorem, ipsum dolor.</div>
                <div className="brand">
                  <div className="brand-title">Lorem.</div>
                  <div className="brand-logo">
                    <img src={require("../img/brand2.png")} alt="" />
                  </div>
                </div>
                <div className="product-rating">
                  <div className="rates">
                    <Rate allowHalf defaultValue={2.5} />
                  </div>
                  <div className="rating-text">(5 reviews)</div>
                </div>
                <div className="price">
                  <div className="current">$30.00</div>
                  <div className="old">$60.00</div>
                </div>
                <div className="checker">
                  <Checkbox defaultValue="In stock" checked />
                  In stock
                </div>
                <div className="vendor">SKU 24-MB26</div>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptatibus aliquam, eius eum laudantium esse doloremque cum
                  amet sed culpa earum facere quidem possimus illo aut.
                </p>
                <div className="offer">
                  <div className="offer-title">Lorem, ipsum dolor.</div>
                  <div className="offer-progress"></div>
                  <div className="offer-counter">
                    <div>
                      <div className="counter-number">78</div>
                      <div className="counter-text">days</div>
                    </div>
                    <div>
                      <div className="counter-number">05</div>
                      <div className="counter-text">hours</div>
                    </div>
                    <div>
                      <div className="counter-number">12</div>
                      <div className="counter-text">minutes</div>
                    </div>
                    <div>
                      <div className="counter-number">47</div>
                      <div className="counter-text">seconds</div>
                    </div>
                  </div>
                </div>
                <div className="add-to-cart">
                  <Form layout="inline">
                    <Form.Item name="count">
                      <InputNumber defaultValue={1} />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" className="xbutton2">
                        Add to cart
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                <div className="add-links">
                  <div className="add-link">
                    <LikeOutlined />
                    Add to wishlist
                  </div>
                  <div className="add-link">
                    <SwapOutlined />
                    Add to compare
                  </div>
                </div>
                <div className="delivery-return">Delivery&return</div>
                <div className="share">
                  Share it:
                  <FacebookOutlined />
                  <InstagramOutlined />
                  <TwitterOutlined />
                  <LinkedinOutlined />
                </div>
                <div className="trust">
                  <img src={require("../img/cart_right_image.png")} alt="" />
                </div>
              </div>
            </Col>
          </Row>
          <div className="product-tabs">
            <ul>
              <li>Lorem.</li>
              <li>Sunt?</li>
              <li>Nam!</li>
              <li>Accusamus!</li>
            </ul>
          </div>
          <div id="product-text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium
            in nulla ratione. Est facilis, illo quas minus recusandae hic.
            Minima facere omnis atque sed amet cupiditate inventore ullam sint.
            Modi, maiores libero error laboriosam consequatur laudantium
            recusandae iste quae atque ipsam repellat iusto fuga voluptas ab
            illo, perspiciatis dicta labore.
          </div>
        </div>
      </Container>
    </Fragment>
  );
}
