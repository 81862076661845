import React, { useEffect, useState, Fragment } from "react";

export default function MapContainer() {
  useEffect(() => {
    const { ymaps } = window;
    ymaps.ready(init);
    function init() {
      // Создание карты.
      var myMap = new ymaps.Map("map", {
        // Координаты центра карты.
        // Порядок по умолчанию: «широта, долгота».
        // Чтобы не определять координаты центра карты вручную,
        // воспользуйтесь инструментом Определение координат.
        center: [55.76, 37.64],
        // Уровень масштабирования. Допустимые значения:
        // от 0 (весь мир) до 19.
        zoom: 7,
      });
      myMap.behaviors.disable('scrollZoom');
    }
  }, []);

  return (
    <div id="map"></div>
  );
}
