import React from "react";
import {
  DownOutlined,
  FacebookOutlined,
  GoogleOutlined,
  InstagramOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

export default function Social() {
  return (
    <div className="social">
      <a href="#" target="_blank" rel="noopener noreferrer">
        <FacebookOutlined />
      </a>
      <a href="#" target="_blank" rel="noopener noreferrer">
        <InstagramOutlined />
      </a>
      <a href="#" target="_blank" rel="noopener noreferrer">
        <TwitterOutlined />
      </a>
      <a href="#" target="_blank" rel="noopener noreferrer">
        <GoogleOutlined />
      </a>
      <a href="#" target="_blank" rel="noopener noreferrer">
        <YoutubeOutlined />
      </a>
    </div>
  );
}
