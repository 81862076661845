import React from 'react'
import Crumbs from './Crumbs'

export default function SubHeader2() {
  return (
    <div id="sub-header2">
      <div className="title">Lorem, ipsum dolor.</div>
      <Crumbs />
    </div>
  )
}
