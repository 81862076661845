import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Rate, Row, Tag, Typography } from "antd";
import { EyeOutlined, HeartOutlined, SwapOutlined } from "@ant-design/icons";

const { Text } = Typography;

export default function ProductPrev({
  title,
  id,
  img1,
  img2,
  url,
  price,
  oldPrice,
  rating,
  rates,
  sale,
}) {
  return (
    <Col span={24} sm={12} lg={8}>
      <div className="product">
        <div className="product-wrapper">
          <div
            className="img img1"
            style={{
              background: `url(${require("../img/" +
                img1)}) no-repeat center center / cover`,
            }}
          ></div>
          <div
            className="img img2"
            style={{
              background: `url(${require("../img/" +
                img2)}) no-repeat center center / cover`,
            }}
          ></div>
          {sale && (
            <div className="sale">
              <Tag color="#f68e56">Sale</Tag>
            </div>
          )}
          <div className="product-controls">
            <HeartOutlined />
            <SwapOutlined />
            <EyeOutlined />
          </div>
        </div>
        <div className="add-to-cart">Add to cart</div>
      </div>
      <div className="product-details center">
        <Link to={`/products/${id}`}>
          <div className="title">{title}</div>
        </Link>
        <div className="product-rates">
          <Rate allowHalf value={rating} />
          {rates && (
            <span>
              <Text type="secondary">{`(${rates})`}</Text>
            </span>
          )}
        </div>
        <div className="product-price">
          <Text type="strong">${price}</Text>
          {oldPrice && (
            <span>
              <Text type="secondary delete">${oldPrice}</Text>
            </span>
          )}
        </div>
      </div>
    </Col>
  );
}
