import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { Fragment } from "react";
import Comments from "../components/Comments";
import Container from "../components/Container";
import NewsPanel from "../components/NewsPanel";
import PostInfo from "../components/PostInfo";
import RelatedPosts from "../components/RelatedPosts";
import RelatedProducts from "../components/RelatedProducts";
import SubHeader2 from "../components/SubHeader2";

export default function Post() {
  return (
    <Fragment>
      <SubHeader2 />
      <Container>
        <div id="post" className="page-content-wrapper">
          <Row gutter={20}>
            <Col span={24} order={2} md={{ span: 8, order: 1 }}>
              <NewsPanel />
            </Col>
            <Col span={24} order={1} md={{ span: 16, order: 2 }}>
              <div id="post-data">
                <div className="title">Lorem ipsum dolor sit amet.</div>
                <PostInfo
                  date="24.04.24"
                  categories={["Mollitia", "Veritatis", "Dicta"]}
                />
                <div className="border">
                  <div
                    className="img"
                    style={{
                      background: `url(${require("../img/post13.jpg")}) no-repeat center center / cover`,
                    }}
                  ></div>
                </div>
                <div className="post-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Labore accusantium dolorum minima corporis, reiciendis
                  doloribus sunt, fugit accusamus magnam, natus suscipit sint
                  odio tempora a? Ut consequatur sint voluptatum eius eligendi
                  vero totam minima culpa? Expedita, quasi impedit minus, non ea
                  adipisci eligendi ipsum harum, rerum libero? Sunt soluta dicta
                  nisi sint! Voluptatem consectetur ipsa minima adipisci.
                  Maiores quaerat et quos molestiae recusandae blanditiis quis
                  excepturi aliquam quam ad voluptatibus non facilis, nisi vitae
                  accusantium corrupti incidunt in odit nemo voluptatem aliquid.
                </div>
              </div>
              <div className="next-section">
                <div className="prev">
                  <CaretLeftOutlined />
                  Previous
                </div>
                <div className="next">
                  Next
                  <CaretRightOutlined />
                </div>
              </div>
              <RelatedPosts />
              <RelatedProducts />
              <Comments />
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}
