import { Col, Row } from "antd";
import React from "react";

export default function Container({ children }) {
  return (
    <div className="box">
      <Row>
        <Col
          style={{ width: "100%" }}
          xl={{ span: 18, offset: 3 }}
          lg={{ span: 24 }}
        >
          {children}
        </Col>
      </Row>
    </div>
  );
}
