import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Basement from "./components/Basement";
import Lower from "./components/Lower";
import TopNav from "./components/TopNav";
import Up from "./components/Up";
import Upper from "./components/Upper";
import Contacts from "./routes/Contacts";
import Lookbook from "./routes/Lookbook";
import Main from "./routes/Main";
import News from "./routes/News";
import Post from "./routes/Post";
import Product from "./routes/Product";
import ProductsRoute from "./routes/ProductsRoute";

function App() {
  return (
    <BrowserRouter>
      <Upper />
      <TopNav />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="lookbook" element={<Lookbook />} />
        <Route path="products/:id" element={<Product />} />
        <Route path="products" element={<ProductsRoute />} />
        <Route path="news/:id" element={<Post />} />
        <Route path="news" element={<News />} />
      </Routes>
      <Basement />
      <Lower />
      <Up />
    </BrowserRouter>
  );
}

export default App;
