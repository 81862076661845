import { Col, Row } from "antd";
import React from "react";

const data = [
  {
    title: "Lorem, ipsum",
    img: "p-44_5.jpg",
    url: "#",
    price: 1500,
    oldPrice: 1900,
    rating: 5,
    rates: 2,
  },
  {
    title: "Unde, aut",
    img: "m-11_1_3.jpg",
    url: "#",
    price: 1300,
    oldPrice: 1700,
    sale: true,
    rating: 4.5,
    rates: 3,
  },
  {
    title: "Consequatur, ipsum",
    img: "g-01_1_1.jpg",
    url: "#",
    price: 2300,
    oldPrice: 2900,
    sale: true,
    rating: 5,
    rates: 1,
  },
];

const services = [
  {
    title: "Blanditiis, corporis!",
    text: "Enim, ipsa odit culpa suscipit ex adipisci ea blanditiis et!",
    icon: "pe-7s-car",
  },
  {
    title: "Neque, corporis?",
    text: "Quod facilis voluptatum aspernatur illo ducimus quos veniam illum earum!",
    icon: "pe-7s-help2",
  },
  {
    title: "Cum, natus!",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Explicabo, quibusdam.",
    icon: "pe-7s-refresh",
  },
  {
    title: "Magni, voluptas?",
    text: "Enim voluptates vitae, consequatur mollitia cumque quo provident debitis ipsa.",
    icon: "pe-7s-door-lock",
  },
];

export default function ProductsPanel() {
  return (
    <div id="products-panel" className="panel">
      <div className="panel-section">
        <div className="title">Lorem, ipsum.</div>
        <ul className="standart-list">
          <li>Lorem, ipsum.</li>
          <li>Labore, id!</li>
          <li>Voluptatum, quo.</li>
          <li>Sequi, odit.</li>
          <li>Aperiam, ullam.</li>
          <li>Molestiae, fugiat.</li>
          <li>Impedit, aliquam.</li>
        </ul>
      </div>
      <div className="panel-section">
        <div className="title">Price</div>
        <div className="label">$30.00 - $135.00</div>
        <div className="progress">
          <div className="progress-dot progress-left"></div>
          <div className="progress-line"></div>
          <div className="progress-dot progress-right"></div>
        </div>
      </div>
      <div className="panel-section">
        <div className="title">Manufacturer</div>
        <ul className="standart-list">
          <li>Lorem (4)</li>
        </ul>
      </div>
      <div className="panel-section">
        <div className="title">Colors</div>
        <div id="colors">
          <div className="color" style={{ background: "#D36B00" }}></div>
          <div className="color" style={{ background: "#C1D5A4" }}></div>
          <div className="color" style={{ background: "#224B0C" }}></div>
          <div className="color" style={{ background: "#FEC260" }}></div>
          <div className="color" style={{ background: "#256D85" }}></div>
          <div className="color" style={{ background: "#E4D192" }}></div>
        </div>
      </div>
      <div className="panel-section">
        <div className="title">Colors</div>
        <div id="sizes">
          <div className="size">XL</div>
          <div className="size">M</div>
          <div className="size">LG</div>
          <div className="size">XS</div>
          <div className="size">S</div>
          <div className="size">XXL</div>
        </div>
      </div>
      <div className="panel-section">
        <div className="title">Compare products</div>
        <ul className="standart-list">
          <li>Qwerty</li>
        </ul>
      </div>
      <div className="panel-section" id="best-sellers">
        <div className="title">Best sellers</div>
        {data.map((x) => (
          <BestSeller {...x} />
        ))}
      </div>
      <div className="panel-section" id="services">
        <div className="title">Shipping & Delivery</div>
        {services.map((x) => (
          <div className="service">
            <div className="icon">
              <span class={x.icon}></span>
            </div>
            <div className="content">
              <div className="title">{x.title}</div>
              <p>{x.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const BestSeller = ({ img, oldPrice, price, title }) => {
  return (
    <div className="prev">
      <Row gutter={10}>
        <Col span={8}>
          <div className="border">
            <div
              className="img"
              style={{
                background: `url(${require("../img/" +
                  img)}) no-repeat center center / cover`,
              }}
            ></div>
          </div>
        </Col>
        <Col span={16}>
          <div className="title">{title}</div>
          <div className="prices">
            <div className="price">${price}</div>
            <div className="old-price">${oldPrice}</div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
