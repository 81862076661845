import { CalendarOutlined } from "@ant-design/icons";
import React from "react";

export default function PostInfo({ categories, date }) {
  return (
    <div className="post-info">
      <div className="date">
        <CalendarOutlined />
        <span>{date}</span>
      </div>
      <div className="categories">
        <div className="label">Categories:</div>
        {categories.map((x) => (
          <div className="cat">{x}</div>
        ))}
      </div>
    </div>
  );
}
