import React from "react";
import { Parallax } from "react-parallax";
import Container from "./Container";

export default function BottomBanner() {
  return (
    <div id="bottom-banner">
      <Parallax
        blur={{ min: -5, max: 5 }}
        bgImage={require("../img/store-bg.jpg")}
        strength={300}
        style={{}}
      >
        <Container>
          <div className="banner-caption">
            <div className="title title5">Lorem, ipsum.</div>
            <p>184 Main, Akhmat St VIC 3021, Grozniy, Chechnya</p>
            <div className="separate"></div>
            <p><strong>Ахмат сила!</strong></p>
            <p>
              Monday to Friday: 9:00 AM – 9:00 PM
              <br />
              Saturday to Sunday: 9:30 AM – 8:00 PM
            </p>
          </div>
        </Container>
      </Parallax>
    </div>
  );
}
