import { Col, Row } from "antd";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import SubHeader2 from "../components/SubHeader2";

const data = [
  {
    id: uuidv4(),
    title: "Unde, aut",
    img: "post08.jpg",
    url: "#",
    categories: [
      "Cupiditate, a explicabo!",
      "Atque, sunt nulla!",
      "Excepturi, veniam dolore?",
      "Ratione, nihil blanditiis!",
    ],
  },
  {
    id: uuidv4(),
    title: "Consequatur, ipsum",
    img: "lookbook1.jpg",
    url: "#",
    categories: [
      "Possimus, hic corrupti!",
      "Suscipit, omnis porro.",
      "Nesciunt, dolorum nisi.",
    ],
  },
  {
    id: uuidv4(),
    title: "Ut, ipsam",
    img: "lookbook2.jpg",
    url: "#",
    categories: [
      "Adipisci, magni consequatur!",
      "Pariatur, inventore quo?",
      "Illum, natus eius.",
    ],
  },
];

export default function Lookbook() {
  return (
    <Fragment>
      <SubHeader2 />
      <div id="lookbook" className="page-content-wrapper">
        <Item1 {...data[0]} />
        <Item2 {...data[1]} />
        <Item1 {...data[2]} />
      </div>
    </Fragment>
  );
}

const Item1 = ({ categories, img, title, url }) => {
  return (
    <div className="look-prev look-prev1">
      <Row>
        <Col sm={{ span: 24 }} md={{ span: 12 }}>
          <div className="border">
            <div
              className="img"
              style={{ background: `url(${require("../img/" + img)}) no-repeat center center / cover` }}
            ></div>
          </div>
        </Col>
        <Col sm={{ span: 24 }} md={{ span: 12 }}>
          <div className="item-content">
            <div className="title">{title}</div>
            <ol>
              {categories.map((x) => (
                <Link to="/">
                  <li>{x}</li>
                </Link>
              ))}
            </ol>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const Item2 = ({ categories, img, title, url }) => {
  return (
    <div className="look-prev look-prev2">
      <Row>        
        <Col span={24} order={2} md={{ span: 12, order: 1 }}>
          <div className="item-content">
            <div className="title">{title}</div>
            <ol>
              {categories.map((x) => (
                <Link to="/">
                  <li>{x}</li>
                </Link>
              ))}
            </ol>
          </div>
        </Col>
        <Col span={24} order={1} md={{ span: 12, order: 2 }}>
          <div className="border">
            <div
              className="img"
              style={{ background: `url(${require("../img/" + img)}) no-repeat center center / cover` }}
            ></div>
          </div>
        </Col>
      </Row>
    </div>
  );
};