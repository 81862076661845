import React, { Fragment, useEffect, useState } from "react";
import { UpOutlined } from "@ant-design/icons";

export default function Up() {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };
  return (
    <Fragment>
      {showButton && (
        <div id="Up" onClick={(_) => scrollToTop()}>
          <UpOutlined />
        </div>
      )}
    </Fragment>
  );
}