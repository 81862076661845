import React from "react";
import { Button, Col, Input, Layout, Row } from "antd";
import Container from "./Container";
import Social from "./Social";
import {
  ContactsOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import SearchForm from "./SearchForm";

const { Footer } = Layout;

export default function Basement() {
  return (
    <Footer style={{ background: `url(${require("../img/footer-bg.jpg")})` }}>
      <section id="upper-footer">
        <Container>
          <Row gutter={20}>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
              <div className="logo">
                <a href="#">Qwerty</a>
              </div>
              <ul className="contacts">
                <li>
                  <p>
                    <ContactsOutlined />
                    <span>Akhmat street 001, Grozniy, Chechnya</span>
                  </p>
                </li>
                <li>
                  <p>
                    <MailOutlined />
                    <span>qwerty@mail.ru</span>
                  </p>
                </li>
                <li>
                  <p>
                    <PhoneOutlined />
                    <span>+001 2233 456</span>
                  </p>
                </li>
                <li>
                  <Social />
                </li>
              </ul>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
              <div className="title title5">Lorem, ipsum.</div>
              <ul>
                <li>
                  <a href="">Lorem, ipsum dolor.</a>
                </li>
                <li>
                  <a href="">Iste, consequuntur magni?</a>
                </li>
                <li>
                  <a href="">Perferendis, sint harum?</a>
                </li>
                <li>
                  <a href="">Expedita, rerum odit.</a>
                </li>
                <li>
                  <a href="">Deleniti, adipisci sunt.</a>
                </li>
              </ul>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
              <div className="title title5">Corrupti, beatae</div>
              <ul>
                <li>
                  <a href="">Eveniet, deleniti fugiat</a>
                </li>
                <li>
                  <a href="">Id, reprehenderit expedita</a>
                </li>
                <li>
                  <a href="">Quae, perspiciatis voluptate!</a>
                </li>
                <li>
                  <a href="">Beatae, et nam</a>
                </li>
                <li>
                  <a href="">Fugit, laboriosam accusantium?</a>
                </li>
              </ul>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
              <div className="title title5">Dicta, incidunt</div>
              <ul>
                <li>Get notified of exclusive deals and new styles</li>
                <li>
                  <div id="newsletter">
                    <SearchForm text="Sign up" />
                  </div>
                </li>
                <li>
                  <div id="payment">
                    <a href="#">
                      <img src={require("../img/payment2.png")} alt="" />
                    </a>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </Footer>
  );
}
