import React from "react";
import { Button, Form, Input, message } from "antd";

export default function SearchForm({ text }) {
  const [form] = Form.useForm();
  return (
    <div className="search-form">
      <Input type="email" />
      <Button>{text}</Button>
    </div>
  );
}
