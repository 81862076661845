import React from "react";
import { v4 as uuidv4 } from "uuid";
import Container from "./Container";
import Products from "./Products";

const data = [
  {
    id: uuidv4(),
    title: "Lorem, ipsum",
    img1: "p-44_5.jpg",
    img2: "p-46_2.jpg",
    url: "#",
    price: 1500,
    oldPrice: 2250,
    rating: 5,
    rates: 2,
  },
  {
    id: uuidv4(),
    title: "Unde, aut",
    img1: "m-11_1_3.jpg",
    img2: "m-12_1_2.jpg",
    url: "#",
    price: 1300,
    oldPrice: 1700,
    sale: true,
    rating: 4.5,
    rates: 3,
  },
  {
    id: uuidv4(),
    title: "Consequatur, ipsum",
    img1: "g-01_1_1.jpg",
    img2: "g-1_1_2.jpg",
    url: "#",
    price: 2300,
    oldPrice: 2900,
    sale: true,
    rating: 5,
    rates: 1,
  },
  {
    id: uuidv4(),
    title: "Animi, rerum",
    img1: "m-14_1.jpg",
    img2: "m-15_1.jpg",
    url: "#",
    price: 2500,
    oldPrice: 3200,
    sale: true,
    rating: 5,
    rates: 1,
  },
  {
    id: uuidv4(),
    title: "Ut, ipsam",
    img1: "grey-1_1_6.jpg",
    img2: "grey-2_1_6.jpg",
    url: "#",
    price: 1800,
    oldPrice: 2200,
    sale: true,
    rating: 4.5,
    rates: 2,
  },
  {
    id: uuidv4(),
    title: "Nihil, tempora",
    img1: "g-4_1.jpg",
    img2: "grey-1_1_6.jpg",
    url: "#",
    price: 3200,
    oldPrice: 3750,
    rating: 5,
    rates: 2,
  },
];

export default function Latest() {
  return (
    <div id="latest" className="standart-section">
      <Container>
        <div className="title section-title center">Lorem, ipsum.</div>
        <Products data={data} />
      </Container>
    </div>
  );
}
